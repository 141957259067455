import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import image1 from '../pictures/elena/elenah.png'; // Update with correct path
import image2 from '../pictures/memes/iphone9.png'; // Update with correct path
import image3 from '../pictures/memes/hosmad.jpg'; // Update with correct path
import trump from '../pictures/memes/trump.png'; // Update with correct path
import { ArrowLeft } from 'lucide-react'; // Importing back icon

export const About = () => {
  const navigate = useNavigate();

  return (
    <div className="p-8">
      <motion.h1
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 2 }}
        className="text-5xl font-bold text-center mb-8"
      >
        Elena, Is She a Ho?
      </motion.h1>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 2, delay: 1 }}
        className="prose lg:prose-2xl mx-auto"
      >
        <p className="text-2xl">
          Welcome to elenaisaho.be, the groundbreaking website created by Jellah, the first to resolve the age-old question that has puzzled minds across generations: Is Elena a ho? 
          With meticulous research and undeniable evidence, we delve deep into this query, leaving no stone unturned.
        </p>

        <section className="my-8">
          <h2 className="text-3xl font-bold">The Beginning</h2>
          <div className="flex flex-col md:flex-row items-center">
            <motion.div
              initial={{ x: '-100vw', opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ type: 'spring', stiffness: 50 }}
              className="md:w-1/2 mb-4 md:mb-0 md:mr-4"
            >
              <img src={image1} alt="Image 1" className="rounded w-full" />
            </motion.div>
            <p className="text-xl md:w-1/2">
              Our journey begins with examining various aspects of Elena's life, scrutinizing the details that led to the creation of this website. 
              The image showcases one of the pivotal moments that brought us closer to the truth. Elena's mysterious smile and the inquisitive look in her eyes set the stage for our exploration. 
              Was it all just a facade? The deeper we looked, the more questions arose.
            </p>
          </div>
        </section>

        <section className="my-8">
          <h2 className="text-3xl font-bold">Unraveling the Mystery</h2>
          <div className="flex flex-col md:flex-row items-center">
            <motion.div
              initial={{ x: '100vw', opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ type: 'spring', stiffness: 50 }}
              className="md:w-1/2 mb-4 md:mb-0 md:mr-4"
            >
              <img src={image2} alt="Image 2" className="rounded w-full" />
            </motion.div>
            <p className="text-xl md:w-1/2">
              This image marks another significant point in our investigation. The setting, the attire, and the expression on Elena's face speak volumes. 
              Could this be the moment where everything started to unravel? The context of this image led us to uncover hidden truths about her affiliations and intentions. 
              The deeper we dug, the more intricate the web became.
            </p>
          </div>
        </section>

        <section className="my-8">
          <h2 className="text-3xl font-bold">Deeper Insights</h2>
          <div className="flex flex-col md:flex-row items-center">
            <motion.div
              initial={{ x: '-100vw', opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ type: 'spring', stiffness: 50 }}
              className="md:w-1/2 mb-4 md:mb-0 md:mr-4"
            >
              <img src={image3} alt="Image 3" className="rounded w-full" />
            </motion.div>
            <p className="text-xl md:w-1/2">
              Continuing our exploration, we uncover more layers of Elena's story. The image captures a moment of defiance and strength. 
              Each detail, from the sunglasses to the confident stance, adds a piece to the puzzle. We began to see patterns, connections, and motives that were previously hidden. 
              Elena's story was becoming clearer, yet more complex.
            </p>
          </div>
        </section>

        <section className="my-8">
          <h2 className="text-3xl font-bold">The Conclusion</h2>
          <div className="flex flex-col md:flex-row items-center">
            <motion.div
              initial={{ x: '100vw', opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ type: 'spring', stiffness: 50 }}
              className="md:w-1/2 mb-4 md:mb-0 md:mr-4"
            >
              <img src={trump} alt="Trump Image" className="rounded w-full" />
            </motion.div>
            <p className="text-xl md:w-1/2">
              Finally, this image ties together the overarching narrative, presenting a conclusion that is both shocking and enlightening. 
              Through this comprehensive analysis, we have answered the question with unparalleled clarity and evidence. 
              Elena's interactions, her choices, and her actions all led us to the final revelation. The truth, as they say, is often stranger than fiction.
            </p>
          </div>
        </section>
      </motion.div>
      <div className="flex justify-center mt-8">
        <button
          onClick={() => navigate('/dashboard')}
          className="flex items-center space-x-2 text-blue-500 hover:underline"
        >
          <ArrowLeft className="h-5 w-5" />
          <span>Back to Dashboard</span>
        </button>
      </div>
    </div>
  );
};
