import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../components/button';
import { Home, LogOut, Info, PanelLeft } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipTrigger } from '../components/tooltip';
import Modal from '../components/Modal';
import thinkingfacememe from './pictures/memes/thinkingface.png';
import huhImage from './pictures/memes/huh.png';
import image1 from './pictures/memes/dontcryspanish.png';
import image2 from './pictures/memes/wowow.jpg';
import image3 from './pictures/memes/bartpetershaha.jpg';
import image4 from './pictures/memes/needuradressspanish.png';
import image5 from './pictures/memes/estoyloco.png';
import image6 from './pictures/memes/estoyloco2.png';
import image7 from './pictures/memes/frank1.jpg';
import image8 from './pictures/memes/frank2.jpg';
import image9 from './pictures/memes/gimmekissspanish.png';
import image10 from './pictures/memes/kanye4president.png';
import image11 from './pictures/memes/swag.png';
import image12 from './pictures/memes/imout.png';
import image13 from './pictures/memes/frank3.jpg';
import image14 from './pictures/memes/frank4.jpg';
import image15 from './pictures/memes/elenahandjellah.jpg';
import image16 from './pictures/memes/haram.jpg';
import image17 from './pictures/memes/salsa.jpg';
import image18 from './pictures/memes/lol.jpg';
import image19 from './pictures/memes/ohnyeah.jpg';
import image20 from './pictures/memes/emo.jpg';
import image21 from './pictures/memes/sickcancel.jpg';
import image22 from './pictures/memes/bald.jpg';
import image23 from './pictures/memes/frfr.jpg';
import image24 from './pictures/memes/spanishorvanish.jpg';
import image25 from './pictures/memes/beatiful.jpg';
import image26 from './pictures/memes/bbygirl.jpg';
import image27 from './pictures/memes/frank5.jpg';
import image28 from './pictures/memes/village.jpg';
import image29 from './pictures/memes/damn.jpg';
import image30 from './pictures/memes/staypure.jpg';
import image31 from './pictures/memes/privatemessagelol.jpg';
import image32 from './pictures/memes/read1.png';
import image33 from './pictures/memes/thotdetector.jpg';
import image34 from './pictures/memes/perhaps.jpg';
import image35 from './pictures/memes/affection.jpg';
import image36 from './pictures/memes/swearword.jpg';
import image37 from './pictures/memes/scarecrow.jpg';
import image38 from './pictures/memes/mnmphil.jpg';
import image39 from './pictures/memes/carl2.jpg';
import image40 from './pictures/memes/carlwheezer.jpg';
import image41 from './pictures/memes/demyth.png';
import image42 from './pictures/memes/meandelena.png';
import image43 from './pictures/memes/maozebron.png';
import image44 from './pictures/memes/elenatalksshit.png';
import image45 from './pictures/memes/biblequote.png';
import image46 from './pictures/memes/openheimer.png';
import image47 from './pictures/memes/azerty.png';
import image48 from './pictures/memes/themyththelegend.png';
import image49 from './pictures/memes/osamebinnein.png';
import image50 from './pictures/memes/waltuh.png';
import image51 from './pictures/memes/jesse.png';
import image52 from './pictures/memes/transflagbb.png';
import image53 from './pictures/memes/hector.png';
import image54 from './pictures/memes/antoinette.png';
import image55 from './pictures/memes/bige.png';
import image56 from './pictures/memes/elenahha.png';
import image57 from './pictures/memes/elenaisautistisch.png';
import image58 from './pictures/memes/elenaisracist.png';
import image59 from './pictures/memes/togetha.jpg';
import image60 from './pictures/memes/winkwink.jpg';
import image61 from './pictures/memes/bbygirlmmhm.jpg';
import image62 from './pictures/memes/deathstare.jpg';
import image63 from './pictures/memes/sexy3.jpg';
import image64 from './pictures/memes/sexy2.jpg';
import image65 from './pictures/memes/sexy.png';
import image66 from './pictures/memes/gibby2.jpg';
import image67 from './pictures/memes/gibby1.jpg';
import image68 from './pictures/memes/ohnyeahrac.png';
import image69 from './pictures/memes/flowersforyou.png';
import image70 from './pictures/memes/shiny.png';
import image71 from './pictures/memes/yourmyn.png';
import image72 from './pictures/memes/elenavewwypwetty.png';
import image73 from './pictures/memes/bigmac.png';
import image74 from './pictures/memes/kip.png';
import image75 from './pictures/memes/redhead.jpg';
import image76 from './pictures/memes/doxdox.jpg';
import image77 from './pictures/memes/datenight.png';
import image78 from './pictures/memes/peacee.jpg';


const images = [image1, image2,image3,image4,image5,image6,image7,image8,image9,image10,image11,image12,image13,image14,image15,image16,
                image17,image18,image19,image20,image21,image22,image23,image24,image25,image26,image27,image28,image29,image30,image31,
                image32,image33,image34,image35,image36,image37,image38,image39,image40,image41,image42,image43,image44,image45,image46,
                image47,image48,image49,image50,image51,image52,image53,image54,image55,image56,image57,image58,image59,image60,image61,image62,image63,
                image64,
                image65,
                image66,
                image67,
                image68,
                image69,
                image70,
                image71,
                image72,
                image73,
                image74,
                image75,
                image76,
                image77,
                image78];
const titles = [
  "is Elena a Ho?",
  "could it be true?",
  "wist je dat vissen dronken kunnen zijn",
  "waarom is jouw oog ovaal ?",
  "skill issue",
  "wist je dat de armeense genocide een inspiratie was voor de holocaust",
  "ongod fr fr",
  "fr fr ongod",
  "melig melig melig",
  "wa is de deal met de vissen nu eigelijk tell me ",
  "i know your racist dont lie ho ",
  "patiënt",
  "registered hypercam 2",
  "bandicam",
  "als je digitale vormgeving gaat doen vind ik dat je deze site wel kan maken",
  "gelukkige 69e verjaardag elenah je bent wel nog cool voor je leeftijd ;)",
  "coole bril",
  "ik had koud brr brr ",
  "in mijn stad hoor je pow pow big mac",
  "ja klikken is ook wel nog cool",
  "vis vis vis",
  "2d cardboard van bart peters",
  "ze noemen mij wel een zakenmaan",
  "eierencrimineel",
  "jellah de eerste",
  "where crackie at haven't heard from her in a while",
  "12/04/24 :)",
  "elenah elenah elenah",
  "je bent 1 van de slechte",
  "je bent 1 van de goeie",
  "minder of meer ?",
  "create my suffering",
  "flipflop ?",
  "het is what the flip",
  "5 duimpjes omhoog",
  "72 duimpjes omlaag",
  "racist building",
  "flakka flakka flakka",
  "kruis met haken",
  "elenah is melig hehe :) ieuw",
  "luister jij ook naar deftones ",
  "elena chacon schildert stenen om haar autisme te stillen",
  "spaans voor u is a mainho",
  "brood om het goed te maken",
  "#elanaheeftautisme",
  "wist je dat ja klikken ook iets doet -_-",
  "this y u a ho",
  "much love <3",
  "298",
  "3.6.2",
  "143.059",
  "3.13.2 <3",
  "heb jij de easter eggs al gevonden ?",
  "i guess we'll never know",
  "elena ik accepteer jou en je autisme ,autist",
  "2009 youtube tutorials",
  "jellah is cool",
  "i wasn't kidding when i said you were getting a fish ;)",
  "i never lied your gifts were digital and physical ho",
  "dem africans expensive goddayum (stillworthittho) ",
  "autist",
  "ik wacht nog op jou presentatie van je droom",
  "wist je dat als je een bril draagt je beter kan zien",
  "date ?",
  "wanna make music ?",
  "i was serious when i said i wanna learn spanish",
  "everything i said about your gifts were true i never lied ;)",
  "de pak komt vrijdag ye i know",
  "ik ga nooit op mijn knieën",
  "ik rizzel jou op wink wink",
  "prrr prrr ",
  "#elenaschildertstenen",
  "vinyl winkels zijn eigelijk wel cool (music date?)",
  "amerikanen zijn niet echt",
  "say it",
  "ik heb net tiktoks terug gevonden van ik die 14 was lol (dark humor ones)",
  "ik heb ooit een spreekbeurt in het frans gedaan over johnny sins op school (ik was er door) , (mijn leerkracht wist niet wie dat was) ,(mijn klas wel want ze lachten veel)",
  "samen shrek kijken mmmhmmmm ;)",
  "elena is racist i just feel it don't lie to me say it",
  "im boutta im boutta im boutta ",
  "ik kan u dgromen laten waarkomen ;) ;)",
  "ik weet wel op wie je hebt gestemd",
  "elena ontken het niet je bent racist ik heb bewijs",
  "spanish date ? ",
  "te quiero ",
  "romans 7:15 is interesting if you think about it ",
  "they dont believe in us , god did",
  "another one",
  "ik en jij verlaten gebouw welke tho mmmhmmm maybe you will see",
  "will smith slaat chris rock ,haha niks anders gewoon grappig",
  "elena je hebt een probleem met racisme",
  "tom van grieken pretty funny ngl hahah , ik ben niet racistisch jij bent racistisch, je hebt de verkeerde persoon dat was connor rousseau die dat zei ahahha",
  "a minorrrrrrrrrr",
  "muziek when ?",
  "zeg het duurt wel lang tot je de juiste combinatie hebt lijkt het mij ;)",
  "wist je dat deze site geupdate wordt als ik mij verveel dus er komen hidden messages bij",
  "wat zou het kunnen zijn ?",
  "waltuh put your d away waltuh",
  "WE NEED TO COOK ",
  "JESSE ",
  "im the one who knocks ",
  "wat als ik zeg dat ik een cardboard versie van bart peters heb gevonden , wat zou er dan gebeuren mmhmm",
  "ik heb het gecontroleerd ze kennen jou echt daar , ongang frr frr",
  "hector dont do it hector dont do it *ding *ding ",
  "de foto gaat over jou ja ;) ;)",
  "ez cash grab hahaha",
  "911 first class , titanic swimteam zijn twee voorbeelden crazyy",
  "world domination",
  "de man met grijs haar is jeffrey epstein voor als je niet mee bent",
  "i never made out on deftones before",
  "social expirement uitvoeren op mensen tesamen ?",
  "wanna be a el powerful duo ? ;)",
  "world domination ?",
  "bart peters is nu juist your welcome mainho ;)",
  "they not like us ",
  "ik ben zo blij dat je meer racist wordt",
  "n word",
  "als ik n zeg kan je het finishen",
  "n met saus",
  "elena your really are my ",
  "n met extra saus",
  "buenos dias ni   a",
  "nog steeds melig diep vanbinnen ik voel het",
  "still waiting for the powerpoint",
  "imma eat u",
  "3.6.16.61.2 ;) ;) ;) ;) ;) ;) xx",
  "527",
  "pwetty wamen walking down the street",
  "rotterdam soon bby no worries",
  "autisme",
  "elena ik accepteer jou en je racisme het is ok",
  "je wordt meer en meer racist elena het komt uit jou ik zie het zeg het elena zeg het no balls",
  "463",
  "340 ?",
  "u my ho now ;)",
  "you upgraded from mainho to myho",
  "oooohn yeah yeah",
  "rocky iv",
  "wanneer ik jou zie voel ik me zoals rocky iv",
  "sexy",
  "escape from the asylum",
  "linker ass cheek",
];

export function MainDashboard() {
    const [showImage, setShowImage] = useState<string>(huhImage);
    const [buttonPosition, setButtonPosition] = useState({ top: 'calc(100% + 10px)', left: '50%' });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [title, setTitle] = useState<string>(titles[0]);
    const navigate = useNavigate();
  
    const handleYesClick = () => {
      setIsModalOpen(true);
    };
  
    const handleNoClick = () => {
      let newTop, newLeft;
      do {
        newTop = Math.random() * 80 + 10 + '%';
        newLeft = Math.random() * 80 + 10 + '%';
      } while (
        (parseFloat(newTop) >= 45 && parseFloat(newTop) <= 55) &&
        (parseFloat(newLeft) >= 40 && parseFloat(newLeft) <= 60)
      );
  
      setButtonPosition({ top: newTop, left: newLeft });
  
      const randomImage = images[Math.floor(Math.random() * images.length)];
      setShowImage(randomImage);
  
      const randomTitle = titles[Math.floor(Math.random() * titles.length)];
      setTitle(randomTitle);
    };
  
    const handleLogout = () => {
      navigate('/login');
    };
  
    return (
      <div className="flex min-h-screen w-full flex-col bg-muted/40 sm:bg-white">
        <aside className="fixed inset-y-0 left-0 z-10 w-14 flex-col border-r bg-background sm:flex">
          <nav className="flex flex-col items-center gap-4 px-2 py-5">
            <Tooltip>
              <TooltipTrigger asChild>
                <Link
                  to="#"
                  className="flex h-9 w-9 items-center justify-center rounded-lg bg-black text-white transition-colors"
                >
                  <Home className="h-5 w-5" />
                  <span className="sr-only">Dashboard</span>
                </Link>
              </TooltipTrigger>
              <TooltipContent side="right">Dashboard</TooltipContent>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger asChild>
                <Link
                  to="/about"
                  className="flex h-9 w-9 items-center justify-center rounded-lg text-muted-foreground transition-colors hover:text-foreground"
                >
                  <Info className="h-5 w-5" />
                  <span className="sr-only">About</span>
                </Link>
              </TooltipTrigger>
              <TooltipContent side="right">About</TooltipContent>
            </Tooltip>
          </nav>
          <nav className="mt-auto flex flex-col items-center gap-4 px-2 py-5">
            <Tooltip>
              <TooltipTrigger asChild>
                <button
                  onClick={handleLogout}
                  className="flex h-9 w-9 items-center justify-center rounded-lg text-muted-foreground transition-colors hover:text-foreground"
                >
                  <LogOut className="h-5 w-5" />
                  <span className="sr-only">Uitloggen</span>
                </button>
              </TooltipTrigger>
              <TooltipContent side="right">Uitloggen</TooltipContent>
            </Tooltip>
          </nav>
        </aside>
        <div className="flex flex-col gap-4 py-4 pl-14 sm:gap-4 sm:py-4 sm:pl-14">
          <header className="sticky top-0 z-30 flex h-14 items-center gap-4 border-b bg-background px-4 sm:static sm:h-auto sm:border-0 sm:bg-transparent sm:px-6">
           
            <div className="relative ml-auto flex-1 md:grow-0"></div>
          </header>
          <main className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
            <div className="flex flex-col items-center justify-center relative">
              <div className="text-center mb-6">
                <h1 className="text-3xl font-bold flex items-center">
                  <img src={thinkingfacememe} alt="Thinking face meme" className="w-16 h-16 mr-4" />
                  {title}
                </h1>
              </div>
              <div className="flex flex-col items-center space-y-4">
                <Button onClick={handleYesClick} className="w-24">Ja</Button>
                <div className="border border-gray-300 p-4 rounded-md mb-4 flex items-center justify-center w-full sm:w-[500px] h-[300px] sm:h-[500px]">
                  {showImage && <img src={showImage} alt="Random" className="max-w-full max-h-full" />}
                </div>
                <Button
                  onClick={handleNoClick}
                  className="w-24"
                  style={{ position: 'absolute', top: buttonPosition.top, left: buttonPosition.left }}
                >
                  Nee
                </Button>
              </div>
            </div>
          </main>
        </div>
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/FdbgeUou8_4"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Modal>
      </div>
    );
  }
  