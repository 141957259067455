import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Use React Router useNavigate
import { Button } from '../components/button';
import { Input } from '../components/input';
import { Label } from '../components/label';
import { Eye, EyeOff } from 'lucide-react';
import { Toaster, toast } from 'react-hot-toast';
import { Loader2 } from 'lucide-react';
import lottie from 'lottie-web';
import Fish from "./background/red.json"; // Ensure this path is correct
import jumpFish from "./background/jumpfish.json";
import Modal from '../components/Modal'; // Ensure this path is correct

interface DashboardProps {
  setIsAuthenticated: (isAuthenticated: boolean) => void;
}

export const Dashboard: React.FC<DashboardProps> = ({ setIsAuthenticated }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal open/close
  const [modalVideoUrl, setModalVideoUrl] = useState(''); // State to manage the URL of the video in the modal
  const animationContainer = useRef<HTMLDivElement>(null);
  const jumpFishContainer = useRef<HTMLDivElement>(null);
  const navigate = useNavigate(); // Use navigate from react-router-dom

  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: animationContainer.current!,
      animationData: Fish,
      renderer: 'svg',
      loop: true,
      autoplay: true,
    });

    return () => {
      animation.destroy();
    };
  }, []);

  useEffect(() => {
    const jumpFishAnimation = lottie.loadAnimation({
      container: jumpFishContainer.current!,
      animationData: jumpFish,
      renderer: 'svg',
      loop: true,
      autoplay: true,
    });

    return () => {
      jumpFishAnimation.destroy();
    };
  }, []);

  const handleLogin = () => {
    setIsLoading(true);
    setIsError(false);
    // Simulate a login process
    setTimeout(() => {
      if (email === 'elena' && password === 'mainho5123') {
        toast.success('Login succesvol!');
        setIsAuthenticated(true); // Update the authentication status
        setIsLoading(false);
        navigate('/dashboard'); // Navigate to dashboard on successful login
      } else {
        toast.error('Ongeldig e-mailadres of wachtwoord.');
        setIsLoading(false);
        setIsError(true);
      }
    }, 1000);
  };

  const handleOpenModal = (url: string) => {
    setModalVideoUrl(url);
    setIsModalOpen(true);
  };

  return (
    <div className="flex h-screen">
      <Toaster />
      <div className="flex w-1/2 items-center justify-center p-8">
        <div className="w-full max-w-md">
          <div className="text-center mb-6">
            <h1 className="text-3xl font-bold">Login</h1>
            <p className="text-muted-foreground">
              Voer hieronder je gebruikersnaam in als één van je ogen ovaal is om in te loggen op je account
            </p>
          </div>
          <div className="space-y-4">
            <div>
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="elena"
                required
              />
            </div>
            <div>
              <div className="flex items-center justify-between">
                <Label htmlFor="password">Password</Label>
                <button
                  onClick={() => handleOpenModal('https://www.youtube.com/embed/P6dVY_MVjA0')}
                  className="text-sm underline"
                >
                  Ben je bang van vissen?
                </button>
              </div>
              <div className="relative">
                <Input
                  id="password"
                  type={isPasswordVisible ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <div
                  className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                  onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                >
                  {isPasswordVisible ? <EyeOff /> : <Eye />}
                </div>
              </div>
            </div>
            <Button
              onClick={handleLogin}
              disabled={isLoading || email.length === 0 || password.length === 0}
              className="w-full"
              variant={isError ? 'destructive' : 'default'}
            >
              {isLoading ? (
                <span className="flex items-center gap-2">
                  <Loader2 className="w-4 h-4 animate-spin" /> Even geduld...
                </span>
              ) : (
                'Login'
              )}
            </Button>
            <Button
              variant="outline"
              className="w-full"
              onClick={() => handleOpenModal('https://www.youtube.com/embed/2q9C1p4x_NU?si=Y8gM5_UUhJTpPGWf')}
            >
              Login with Google
            </Button>
          </div>
          <div ref={jumpFishContainer} className="mt-8 w-full h-64"></div>
        </div>
      </div>
      <div className="flex w-1/2 items-center justify-center bg-black">
        <div ref={animationContainer} className="w-full h-full"></div>
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="w-full h-64">
          <iframe
            width="1000"
            height="1000"
            src={modalVideoUrl}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="w-full h-full"
          ></iframe>
        </div>
      </Modal>
    </div>
  );
};
